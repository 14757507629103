export enum CustomEventType {
    moduleType = "api_error",
}
export enum CustomEventReferrerPageType {
    referrerPageType = "Login Popup",
    cart = "Cart",
    address = "Address",
    payment = "Payment",
    listing = "Listing"
}
export enum CustomEventReferrerPageValue {
    default = "default",
}